import { ref, onMounted } from '@vue/composition-api'
import useHttp from '@/comp-functions/useHttp'
// import store from '@/store'
import router from '@/router'

// to access i18n
import useLocalization from '@/comp-functions/utils/useLocalization'

// // Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

/**
 * Logic for handling submit into the table
 * Will use in many component, be careful when want to change this function
 * @param {object} payload
 * @param {string} payload.url for api endpoint (ex: master/accounts), used for request to api
 * @param {string} payload.localeContextPath see in @/lib/i18n/locale, (ex: 'apps.masterDataCOA.singular.coa')
 * @param {string} payload.redirectPathName like apps-master-data-coa
 */
export default function useListTable ({ url, localeContextPath, redirectPathName }) {
  const { $t } = useLocalization()
  const { $post, $get, $put, $delete } = useHttp()

  // check route params
  const routeParams = router.currentRoute.params
  const paramsId = router.currentRoute.params.id
  if (paramsId) {
    url += paramsId && `/${paramsId}`
  }

  const entityName = $t(localeContextPath)
  const actions = ref({
    isPreview: false,
    isEditMode: false
  })

  const $toast = useToast()

  const payload = {
    url
  }

  // form ref to validate when submit
  const formRef = ref(null)

  // refs for first element to focus when mounted
  const firstFocusInput = ref(null)

  // if you want vue-select component to be focus, use this
  const firstFocusSelectInput = ref(null)

  const get = async ({url, data = {} }) => {
    const response = await $get({  ...payload, url, ...data })
    return response.data
  }

  // change state from preview to edit mode
  const changeToEdit = () => {
    actions.value.isPreview = false
    actions.value.isEditMode = true
  }

  /**
   * For handling getting data by url params (id)
   * Will use in many component, be careful when want to change this function
   * @param {string} id to get single value from api (ex: master/accounts/:id)
   */
  const show = async () => {
    const response = await $get(payload)
    return response.data
  }

  /**
   * For handling getting data by url params (id)
   * Will use in many component, be careful when want to change this function
   * @param {string} id to get single value from api (ex: master/accounts/:id)
   * @param {function} $swal swal function
   */
  // const destroy = async ({ $swal, url, callbackSuccess, useRedirect = true }) => {
  const destroy = async ($swal, url = null, callbackSuccess = null, option = { useRedirect: true }) => {
    url = url ? url : payload.url
    let result = {}
    let isSuccess = false
    const { useRedirect } = option

    await $swal({
      title: `${$t('confirm.deleteThis')} ${entityName} ?`,
      text: $t('info.notAbleRecover'),
      icon: 'warning',
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-flat-secondary mb-sm-1 mb-md-0',
        actions: 'd-flex flex-sm-column flex-md-row justify-content-around'
      },
      confirmButtonText: $t('globalActions.deleteButton'),
      cancelButtonText: $t('globalActions.cancelDeleteButton')
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        await $delete({
          ...payload,
          url
        })
          .then(response => { result = response; isSuccess = true })
          .catch(error => { result = error })

        if (redirectPathName && useRedirect) {
          router.replace({ name: `${redirectPathName}-list` })
        }

        $toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Success',
            icon: 'BellIcon',
            variant: 'success',
            text: `${$t('feedback.successDeleted')} ${entityName}!`
          }
        })

        if (typeof callbackSuccess === 'function') {
          callbackSuccess()
        }
      } else {
        result = 'CANCEL'
      }
    })

    return new Promise((resolve, reject) => {
      if (isSuccess) {
        resolve(result)
      } else {
        if (result === 'CANCEL') {
          console.error('Delete action canceled');
        }
        reject(result)
      }
    })
  }

  /**
   * For handling creating data
   * Will use in many component, be careful when want to change this function
   * @param {function} $swal swal function
   * @param {object} data data to be post
   */
  const store = async ({ $swal, data, url, useRedirect = true, onCancelCallback = () => {} }, additionalCallbackSuccess) => {
    url = url ? url : payload.url
    const validated = await formRef.value.validate()
    let result = {}
    let isSuccess = false

    if (validated) {
      await $swal({
        title: `${actions.value.isEditMode ? $t('confirm.updateThis') : $t('confirm.createNew')} ${entityName} ?`,
        text: `${actions.value.isEditMode ? $t('info.ofCourse') : $t('info.afterCreated')} ${$t('info.stillCanDelete')}.`,
        icon: 'question',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      })
        .then(async ({ isConfirmed }) => {
          if (isConfirmed) {
            const callbackSuccess = (response) => {
              result = response
              isSuccess = true

              if (redirectPathName && useRedirect) {
                router.replace({ name: `${redirectPathName}-list` })
              }

              $toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: `${$t('feedback.success')} ${actions.value.isEditMode ? $t('feedback.updated') : $t('feedback.createdNew')} ${entityName}!`
                }
              })

              if (typeof additionalCallbackSuccess === 'function') {
                additionalCallbackSuccess()
              }
            }

            if (actions.value.isEditMode || actions.value.isPreview) {
              await $put({
                data,
                ...payload,
                url
              })
                .then(callbackSuccess)
                .catch(error => result = error)
            } else {
              await $post({
                data,
                ...payload,
                url
              })
                .then(callbackSuccess)
                .catch(error => result = error)
            }

          } else {
            result = 'CANCEL'
            onCancelCallback()
          }
        })
    }

    return new Promise((resolve, reject) => {
      if (isSuccess) {
        resolve(result)
      } else {
        if (result === 'CANCEL') {
          console.error('Create action canceled');
        }
        reject(result)
      }
    })
  }

  /**
   * For handling creating data (custom url and swal text)
   * Will use in many component, be careful when want to change this function
   * @param {function} $swal swal function
   * @param {object} data data to be post
   * @param url
   * @param text
   */
  const customStore = async ({ $swal, data, url, text = {} }) => {
    if (!text) {
      text.swalTitle = `${actions.value.isEditMode ? $t('confirm.updateThis') : $t('confirm.createNew')} ${entityName} ?`
      text.swalText = `${actions.value.isEditMode ? $t('info.ofCourse') : $t('info.afterCreated')} ${$t('info.stillCanDelete')}.`
      text.successText = `${$t('feedback.success')} ${actions.value.isEditMode ? $t('feedback.updated') : $t('feedback.createdNew')} ${entityName}!`
    }

    const validated = await formRef.value.validate()
    if (validated) {
      $swal({
        title: text.swalTitle,
        text: text.swalText,
        icon: 'question',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      })
        .then(async ({ value }) => {
          if (value) {
            const callbackSuccess = () => {
              if (redirectPathName) router.replace({ name: `${redirectPathName}` })
              $toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: text.successText
                }
              })
            }

            if (actions.value.isEditMode) {
              $put({
                data,
                ...payload,
                url
              }).then(callbackSuccess)
            } else {
              $post({
                data,
                ...payload,
                url
              }).then(callbackSuccess)
            }

          }
        })
    }
  }

  const autoFocus = () => {
    if (firstFocusInput.value) firstFocusInput.value.focus()

    if (firstFocusSelectInput.value) firstFocusSelectInput.value.$refs.search.focus()
  }

  onMounted(() => {
    autoFocus()
  })


  return {
    routeParams,
    paramsId,
    formRef,
    firstFocusInput,
    firstFocusSelectInput,
    get,
    actions,
    changeToEdit,
    show,
    destroy,
    store,
    customStore
  }
}
